class Lesson {
  constructor(lessonObj) {
    this.id ||= lessonObj.id
    this.logs ||= []
    Object.assign(this, lessonObj)
    this.state ||= 'confirmed_by_instructor'
  }

  toFirestore() {
    return { ...this }
  }

  isPendingConfirmation() {
    return this.state === 'pending_instructor_confirmation'
  }

  isConfirmedByInstructor() {
    return this.state === 'confirmed_by_instructor'
  }

  isDeclinedByInstructor() {
    return this.state === 'declined_by_instructor'
  }

  isRescheduleRequested() {
    return this.changes && Object.values(this.changes.approvals).some(approval => approval.approved === null)
  }

  isCancelled() {
    return this.state === 'cancelled'
  }

  isCompleted() {
    if (this.state === 'completed') return true // old lessons when we still had the "complete lesson" button. TODO: remove this after updating old data
    if (!this.isConfirmedByInstructor()) return false

    const today = new Date()
    const formattedToday = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`

    return formattedToday > this.date
  }

  stateGroup() {
    if (this.state.includes('declined') || this.isCancelled()) return 'cancelled'
    if (this.isCompleted()) return 'completed'
    if (this.state.includes('pending') || this.isRescheduleRequested()) return 'pending'
    if (this.state.includes('confirmed')) return 'upcoming'
    return 'other'
  }

  humanState() {
    if (this.isCancelled()) return 'Cancelled'
    if (this.isCompleted()) return 'Completed'
    if (this.isDeclinedByInstructor()) return 'Declined'
    if (this.isRescheduleRequested()) return 'Reschedule Requested'
    if (this.isPendingConfirmation()) return 'Pending Confirmation'
    if (this.isConfirmedByInstructor()) return 'Confirmed'
  }

  paymentStatus() {
    let result = ''
    if (this.partOfPackage()) {
      result += 'Part of Lesson Package'
    } else {
      if (this.needToCollectPayment()) {
        result += 'Payment Not Collected'
      } else if (this.paymentCollected()) {
        result += 'Payment Collected'
      } else {
        result += `Online Payment (${this.paymentInfo?.status})`
      }
    }
    return result
  }

  partOfPackage() {
    return this.paymentInfo.packageId && this.paymentInfo.metadata?.pricingPrice === 0
  }

  offlinePayment() {
    this.paymentInfo.metadata ||= {}
    return this.paymentInfo.metadata.paymentMethod === 'offline'
  }

  offlineStudent() {
    return this.studentId?.startsWith('offline_')
  }

  needToCollectPayment() {
    return this.offlinePayment() && this.paymentInfo.status === 'authorized'
  }

  paymentCollected() {
    return this.offlinePayment() && this.paymentInfo.status === 'paid'
  }

  toIcs(currentUserType) {
    const result = {
      start: this.date.split('-').map(Number).concat(this.time.split(':').map(Number)),
      startInputType: 'local',
      duration: { hours: Math.floor(this.length / 60), minutes: this.length % 60 },
      title: `Golf lesson with ${currentUserType === 'student' ? this.instructor.name : this.student.name}`,
      url: `${window.location.origin}/my/lessons/${this.id}`,
      categories: ['Golf lesson'],
      busyStatus: 'BUSY',
      // description: 'Annual 10-kilometer run in Boulder, Colorado',
      // geo: { lat: 40.0095, lon: 105.2669 },
      // status: 'CONFIRMED',
      // organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' }
    }

    if (this.location) result.location = `${this.location.name}, ${this.location.address}`

    return result
  }
}

module.exports = { Lesson }
