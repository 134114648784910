<template>
  <ion-accordion-group v-if="lesson.lessonType">
    <ion-accordion value="overrides">
      <ion-item slot="header" color="light" data-testid="overrides-block-accordion">
        <ion-label>
          <ion-note>Customize price, length, or description</ion-note>
        </ion-label>
      </ion-item>
      <div slot="content">
        <ion-item
          v-if="overrides.pricing" data-testid="custom-price-block" lines="none"
          style="overflow:visible" class="ion-margin-bottom ion-margin-top"
        >
          <ion-input
            v-model.number="overrides.pricing.price" type="number" mode="md"
            fill="outline" label="Price" label-placement="stacked"
            debounce="200" :class="{'ion-invalid ion-touched': v$.pricing.price.$invalid && v$.pricing.price.$dirty}"
          >
            <span slot="start">$</span>
          </ion-input>
        </ion-item>

        <ion-item
          v-if="overrides.pricing" data-testid="custom-length-block" lines="none"
          style="overflow:visible" class="ion-margin-bottom ion-margin-top"
        >
          <ion-input
            v-model.number="overrides.length" type="number" mode="md"
            fill="outline" label="Lesson Length" label-placement="stacked"
            debounce="200" :class="{'ion-invalid ion-touched': v$.length.$invalid && v$.length.$dirty}"
          >
            <span slot="start">min.</span>
          </ion-input>
        </ion-item>

        <ion-item lines="none" data-testid="custom-description-block" style="overflow:visible">
          <ion-textarea
            v-model="overrides.description" label="Description"
            label-placement="stacked" rows="3" :auto-grow="true"
            mode="md" fill="outline" debounce="200"
          />
        </ion-item>
      </div>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script setup>
import { IonAccordion, IonAccordionGroup, IonInput, IonItem, IonLabel, IonNote, IonTextarea } from '@ionic/vue'
import { ref, watchEffect } from 'vue'
import { helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

const props = defineProps({
  lesson: { type: Object, required: true },
})

const emit = defineEmits(['updateOverrides'])

const overrides = ref({})

// Update overrides when lesson values change
watchEffect(() => {
  overrides.value = {
    pricing: props.lesson.pricing,
    length: props.lesson.length,
    description: props.lesson.description,
  }
})

// Emit changes to parent when overrides change
watchEffect(() => {
  emit('updateOverrides', overrides.value)
})

const rules = {
  pricing: {
    price: {
      required: helpers.withMessage('Please enter price', value => !!value),
    },
  },
  length: {
    required: helpers.withMessage('Please enter length', value => !!value),
  },
}

const v$ = useVuelidate(rules, overrides)
v$.value.$touch()
</script>
