<template>
  <ion-page>
    <ion-tabs v-if="currentUser && userDoc">
      <sm-header />
      <ion-page v-if="currentUser && !userDoc?.onboarded && route.name != 'account'" class="ion-padding">
        <ion-content color="light" class="ion-padding">
          <p style="margin-top: 3em">Please setup your account to start your journey.</p>
          <ion-button expand="block" color="success" :router-link="{name: 'account'}">Setup Account</ion-button>
        </ion-content>
      </ion-page>
      <ion-router-outlet />
      <ion-tab-bar slot="bottom" data-testid="bottom-tabs-menu">
        <template v-if="isOnboarded">
          <ion-tab-button tab="home" href="/my/home">
            <template v-if="userStore.is.demoStudent">
              <ion-badge id="demo-student-badge" color="tertiary" class="animate__animated animate__pulse animate__infinite animate__slow">DEMO</ion-badge>
            </template>
            <ion-icon aria-hidden="true" :icon="homeOutline" />
            <ion-label>Home</ion-label>
          </ion-tab-button>

          <ion-tab-button v-if="isInstructor" tab="schedules" href="/my/schedules">
            <ion-icon
              aria-hidden="true" :icon="calendarOutline"
              :class="!hasSchedules ? 'animate__animated animate__swing animate__infinite' : ''"
            />
            <ion-label>Schedule</ion-label>
          </ion-tab-button>

          <ion-tab-button
            tab="lessons" class="tab-color-override"
            :selected="route.name == 'lessons'"
            @click="changeRouteWithoutAnimation('/my/lessons/')"
          >
            <ion-icon aria-hidden="true" :icon="golfOutline" />
            <ion-label>
              Lessons
              <pending-lessons-count />
            </ion-label>
          </ion-tab-button>

          <ion-tab-button
            v-if="isStudent" href="/my/lessons/new" tab="newLesson"
            :selected="route.name == 'newLesson'"
            @click="changeRouteWithoutAnimation('/my/lessons/new')"
          >
            <ion-icon
              aria-hidden="true"
              :icon="addCircleOutline"
            />
            <ion-label>Book Lesson</ion-label>
          </ion-tab-button>

          <ion-tab-button v-if="isInstructor" tab="students" href="/my/students">
            <ion-icon
              aria-hidden="true" :icon="peopleOutline"
            />
            <ion-label>Students</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="chats" href="/my/chats">
            <ion-icon aria-hidden="true" :icon="chatbubblesOutline" />
            <ion-label>
              Chats
              <ion-badge
                v-if="unreadChatsCount" color="danger" data-testid="unread-chats-count"
                class="animate__animated animate__swing"
              >
                {{ unreadChatsCount }}
              </ion-badge>
            </ion-label>
          </ion-tab-button>
        </template>
        <!-- <ion-tab-button
          v-if="isInstructor" tab="students" href="/my/students"
          disabled
        >
          <ion-icon aria-hidden="true" :icon="peopleOutline" />
          <ion-label>Students</ion-label>
        </ion-tab-button> -->

        <ion-tab-button tab="account" href="/my/account">
          <ion-icon aria-hidden="true" :icon="settingsOutline" />
          <ion-label>Account</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup>
import {
  IonBadge, IonButton, IonContent, IonIcon, IonLabel, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, useIonRouter,
} from '@ionic/vue'
import { addCircleOutline, calendarOutline, chatbubblesOutline, golfOutline, homeOutline, peopleOutline, settingsOutline } from 'ionicons/icons'
import { computed, watch } from 'vue'
import { useCollection, useDocument } from 'vuefire'
import Intercom from '@intercom/messenger-js-sdk'
import LogRocket from 'logrocket'
import { useCurrentUser } from 'vuefire'
import { useRoute } from 'vue-router'

import PendingLessonsCount from '@/4_features/menu/PendingLessonsCount.vue'
import { SM } from '@/globals'
import SmHeader from '@/views/layout/SmHeader.vue'
import { useChatsStore } from '@/5_entities/chats/store'
import { useUserStore } from '@/stores/user'

const route = useRoute()
const ionRouter = useIonRouter()
const currentUser = useCurrentUser()
const userStore = useUserStore()
const userDoc = useDocument(userStore.userDocRef)
const chatsStore = useChatsStore()
const userChats = useCollection(chatsStore.userChatsRef(), { ssrKey: 'user-chats' })

const isOnboarded = computed(() => userDoc?.value?.onboarded)
const isInstructor = computed(() => userDoc?.value?.type === 'instructor')
const isStudent = computed(() => userDoc?.value?.type === 'student')
const hasSchedules = computed(() => userDoc?.value?.instructor?.lessonTypes?.length > 0)

const unreadChatsCount = computed(() => {
  const unreadChats = userChats.value.filter(chat => !chatsStore.getChatInfo(chat).isRead)
  return unreadChats.length > 0 ? unreadChats.length : ''
})

// analytics
watch(userDoc, async () => {
  if (!userDoc.value) return
  const intercomData = {
    app_id: 'dfro638p',
    user_id: userDoc.value.id,
    name: userDoc.value.name,
    email: userDoc.value.email,
    phone: userDoc.value.phone,
    created_at: userDoc.value.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    custom_launcher_selector: '#intercom_custom_launcher',
    ['User Type']: userDoc.value.type,
    ['User About']: userDoc.value.about,
  }
  if (SM.env !== 'development') {
    Intercom(intercomData)
    LogRocket.identify(userDoc.value.email, {
      name: userDoc.value.name,
      email: userDoc.value.email,
      // Add your own custom user variables here, ie:
      userType: userDoc.value.type,
    })
  }
})
// /analytics

async function changeRouteWithoutAnimation(to) {
  console.log('ignore following error (internal ion-router bug)')
  ionRouter.push(to, 'none')
}
</script>

<style scoped>
#tab-button-chats ion-badge{
  position: absolute;
  top: 3px;
  right: 0px;
}
#tab-button-lessons ion-badge{
  position: absolute;
  top: 3px;
  right: 0px;
}
#tab-button-newLesson {
  color: green;
  transform:scale(1.2);
}
#tab-button-newLesson ion-label {
  font-weight: bold;
  margin-top: -2px;
}
#tab-button-newLesson.tab-selected{
  color: var(--ion-color-primary) !important;
}
#demo-student-badge {
  position: absolute;
  top: 0;
  left: 10px;
}
</style>
