import { collection, deleteDoc, doc, or, query, where } from 'firebase/firestore'
import { useCollection, useCurrentUser, useDocument, useFirestore } from 'vuefire'

import { createInvite, declineInvite } from '@/firebase'
import { useUserStore } from '@/stores/user'

const db = useFirestore()

export class LessonInvitesRepository {
  constructor() {
    this.currentUser = useCurrentUser()
    this.userStore = useUserStore()
  }

  getInvites() {
    const invitesRef = query(
      collection(db, 'invites'),
      or(
        where('instructorId', '==', this.currentUser.value.uid),
        where('studentId', '==', this.userStore.userData().id),
      ),
    )
    return useCollection(invitesRef, { ssrKey: 'user-invites' })
  }

  getRef(id) {
    return doc(collection(db, 'invites'), id)
  }

  get(id) {
    return useDocument(this.getRef(id))
  }

  async delete(invite) {
    await deleteDoc(this.getRef(invite.id))
  }

  async createInvite(invite) {
    return await createInvite({ invite })
  }

  async decline(invite) {
    return await declineInvite({ invite })
  }
}
