<template>
  <ion-badge
    v-if="count" color="danger" data-testid="pending-lessons-count"
    class="animate__animated animate__swing"
  >
    {{ count }}
  </ion-badge>
</template>

<script setup>
import { IonBadge } from '@ionic/vue'
import { computed } from 'vue'

import { LessonInvitesRepository } from '@/data/LessonInvite'
import { LessonsRepository } from '@/data/LessonsRepository'
import { groupBy } from 'lodash'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const InvitesRepo = new LessonInvitesRepository()
const invites = InvitesRepo.getInvites()

const lessonsRepo = new LessonsRepository()
const lessons = lessonsRepo.getUserLessons()
const count = computed(() => {
  let result = groupBy(lessons.value, lesson => lesson.stateGroup())['pending'] || []
  if (userStore.is.student) {
    result = result.filter(lesson => !lesson.isPendingConfirmation())
    const pendingInvites = invites.value.filter(invite => invite.inviteState === 'pending')
    result.push(...pendingInvites)
  }
  return result.length > 0 ? result.length : ''
})
</script>
