<template>
  <div v-if="lessons.length || displayEmptyState">
    <ion-segment
      v-if="lessons.length" v-model="subPage" scrollable="true"
      data-testid="lessons-statuses"
    >
      <template v-for="state in STATES" :key="state">
        <ion-segment-button v-if="displayEmptyState || filterLessons(state).length" :value="state" :data-testid="`lessons-status-${state}`">
          <ion-label class="ion-text-capitalize">
            {{ state }}
            <ion-badge v-if="filterLessons(state).length && ['pending', 'upcoming'].includes(state)">{{ filterLessons(state).length }}</ion-badge>
          </ion-label>
        </ion-segment-button>
      </template>
    </ion-segment>
    <template v-if="filterLessons(subPage).length == 0">
      <br>
      <ion-card style="text-align:center">
        <br>
        <ion-icon :icon="warningOutline" style="font-size: 64px;" />
        <ion-card-header>
          <ion-card-title class="ion-text-capitalize">No {{ subPage }} Lessons</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <p v-if="userStore.is.student">Click "Book" to book a new lesson.</p>
          <p v-if="userStore.is.instructor">You will be notified when a student books a lesson with you.</p>
        </ion-card-content>
      </ion-card>
    </template>
    <ion-list v-if="filterLessons(subPage).length > 0">
      <template v-for="lesson in filterLessons(subPage)">
        <lesson-item
          v-if="lesson._type === 'lesson'" :key="lesson.id" :lesson="lesson"
          :current-user="userStore.is.type"
          :router-link="{name: 'lessonPage', params: {lessonId: lesson.id}}"
          :display-user="!props.filterByUser"
        />
        <invite-item
          v-else :key="`invite-${lesson.id}`" :invite="lesson"
          :display-user="!props.filterByUser"
        />
      </template>
    </ion-list>
    <p class="ion-padding-horizontal">
      <ion-note>
        <span v-if="subPage == 'pending'">Lessons awaiting confirmation or rescheduling</span>
        <span v-if="subPage == 'upcoming'">Lessons scheduled for a future date</span>
        <span v-if="subPage == 'completed'">Lessons that have been successfully finished</span>
      </ion-note>
    </p>
  </div>
</template>

<script setup>
import {
  IonBadge, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
  IonIcon, IonLabel, IonList, IonNote, IonSegment, IonSegmentButton,
} from '@ionic/vue'
import { computed, ref, watchEffect } from 'vue'
import { groupBy } from 'lodash'
import { warningOutline } from 'ionicons/icons'

import InviteItem from '@/shared/components/InviteItem.vue'
import { LessonInvitesRepository } from '@/data/LessonInvite'
import LessonItem from '@/views/golfers/lessons/LessonItem.vue'
import { LessonsRepository } from '@/data/LessonsRepository'
import { useUserStore } from '@/stores/user'

const props = defineProps({
  // displayBookButton: { required: false, type: Boolean, default: true },
  filterByUser: { required: false, type: Object, default: null },
  displayEmptyState: { required: false, type: Boolean, default: false },
})

const STATES = ['pending', 'upcoming', 'completed']

const userStore = useUserStore()

const lessonsRepo = new LessonsRepository()
const InvitesRepo = new LessonInvitesRepository()

const subPage = ref('upcoming')
let lessons
if (props.filterByUser) {
  lessons = lessonsRepo.getLessonsWithStudent(props.filterByUser.id)
} else {
  lessons = lessonsRepo.getUserLessons()
}

const invites = InvitesRepo.getInvites()
const filteredInvites = computed(() => {
  if (!props.filterByUser) return invites.value
  return invites.value.filter(invite => invite.instructorId === props.filterByUser.id || invite.studentId === props.filterByUser.id)
})

const lessonsGrouped = computed(() => {
  return groupBy(lessons.value, lesson => lesson.stateGroup())
})
const invitesGrouped = computed(() => {
  return groupBy(filteredInvites.value, invite => invite.inviteState)
})

watchEffect(() => {
  if (lessonsGrouped.value) {
    const firstStateWithLessons = STATES.find(state => lessonsGrouped.value[state]?.length > 0)
    subPage.value = firstStateWithLessons || 'upcoming'
  }
  if (userStore.is.student && invitesGrouped.value['pending']?.length) {
    subPage.value = 'pending'
  }
})

function filterLessons(state) {
  const lessons = (lessonsGrouped.value[state] || []).sort((lesson1, lesson2) => lesson1.datetime - lesson2.datetime) // sort by date, oldest to newest (most near to now first)
  const invites = (invitesGrouped.value[state] || []).sort((invite1, invite2) => invite1.datetime - invite2.datetime)
  lessons.forEach(lesson => lesson._type = 'lesson')
  invites.forEach(invite => invite._type = 'invite')
  const result = [...lessons, ...invites]
  if (state === 'completed') result.reverse() // cancelled and completed lessons should be most recent first
  return result
}
</script>

<style scoped>
ion-badge {
  font-weight: normal;
  margin-left: -3px;
  font-size: 10px;
}
</style>
