<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title v-if="invite" data-testid="invite-status">
          <span style="text-transform: capitalize;">{{ invite.inviteState }}</span>
          Lesson Invite
        </ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'lessons'}" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="invite" color="light" data-testid="lesson-invite-page">
      <lesson-card :lesson="invite" :current-user="userStore.is.type" />

      <p v-if="invite.inviteState === 'completed'" class="ion-padding">
        <ion-icon slot="start" :icon="checkmarkOutline" />
        Invite was accepted.
        <router-link :to="{ name: 'lessonPage', params: { lessonId: invite.lessonId } }">
          Go to Lesson »
        </router-link>
      </p>

      <events-list :invite="invite" />
    </ion-content>
    <ion-footer v-if="invite?.inviteState === 'pending'" translucent="true">
      <ion-toolbar>
        <ion-button
          v-if="userStore.is.type === 'instructor'"
          data-testid="invite-cancel" color="danger"
          fill="outline" expand="block" @click="deleteInvite"
        >
          <ion-icon slot="start" :icon="ban" />
          Delete Invite
        </ion-button>
        <ion-grid v-if="userStore.is.type === 'student'">
          <ion-row>
            <ion-col>
              <ion-button
                color="danger" fill="outline" expand="block"
                data-testid="invite-decline" @click="declineInvite"
              >
                <ion-icon slot="start" :icon="close" />
                Decline
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button
                data-testid="invite-accept" expand="block" color="success"
                fill="solid"
                @click="acceptInvite"
              >
                <ion-icon slot="start" :icon="checkmarkOutline" />
                Accept
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script setup>
import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/vue'
import { ban, checkmarkOutline, close } from 'ionicons/icons'
import { useRoute, useRouter } from 'vue-router'
import { useDocument } from 'vuefire'

import EventsList from '@/shared/components/EventsList.vue'
import LessonCard from '@/views/golfers/lessons/LessonCard.vue'
import { LessonInvitesRepository } from '@/data/LessonInvite'
import { sendRequest } from '@/6_shared/utils/sendRequest'
import { useUserStore } from '@/stores/user'

const route = useRoute()
const router = useRouter()
const userStore = useUserStore()
const InvitesRepo = new LessonInvitesRepository()
const invite = useDocument(InvitesRepo.getRef(route.params.inviteId))

async function deleteInvite() {
  await sendRequest('Deleting Lesson Invite...', async () => {
    await InvitesRepo.delete(invite.value)
    router.back()
  }, invite)
}

async function declineInvite() {
  await sendRequest('Declining Lesson Invite...', async () => {
    await InvitesRepo.decline(invite.value)
    router.back()
  }, invite)
}

async function acceptInvite() {
  router.push({
    name: 'bookLesson',
    params: { instructorId: invite.value.instructorId },
    query: { inviteId: invite.value.id },
  })
}
</script>
