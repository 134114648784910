<template>
  <ion-page data-testid="timeslot-modal">
    <ion-header>
      <ion-toolbar>
        <ion-title>
          Timeslot Summary
        </ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="true" @click="cancel" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light">
      <lesson-card
        v-model:notes="lesson.notes"
        :current-user="userStore.is.type"
        :lesson="lesson"
      />

      <ion-card>
        <ion-card-content>
          <strong>Cancellation & Refund Policy:</strong>
          <ul>
            <li>Cancellations: Full refund (minus processing fee) with 24+ hours notice. No refund otherwise.</li>
            <li>Weather: Reschedule or full refund available.</li>
          </ul>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-content>
          <ion-text v-if="!goodToGo" color="dark">
            <strong>Please accept to continue:</strong>
            <br>
          </ion-text>
          <ion-checkbox v-model="agreements.tos" label-placement="end">
            I agree to the <a @click.prevent="tosModalRef.$el.present()">Terms of Service</a>
          </ion-checkbox>
          <ion-modal ref="tosModalRef">
            <tos-modal @dismiss="tosModalRef.$el.dismiss()" />
          </ion-modal>
        </ion-card-content>
      </ion-card>
      <timeslot-checkout
        mode="timeslot"
        :timeslot="timeslot"
        :good-to-go="goodToGo"
        :payment-methods="instructor.paymentMethods"
        :demo-mode="userStore.is.demoStudent"
        @payment-complete="createLesson"
      />
    </ion-content>
  </ion-page>
</template>

<script setup>
const props = defineProps({
  timeslot: { required: true, type: Object },
  instructor: { required: true, type: Object },
})

const emit = defineEmits(['cancel', 'create:lesson'])

import {
  IonBackButton, IonButtons, IonCard,
  IonCardContent, IonCheckbox, IonContent, IonHeader, IonModal, IonPage, IonText,
  IonTitle, IonToolbar,
} from '@ionic/vue'
import { computed, reactive, ref } from 'vue'

import LessonCard from '@/views/golfers/lessons/LessonCard.vue'
import TimeslotCheckout from '@/views/golfers/lessons/TimeslotCheckout.vue'
import TosModal from '@/3_widgets/timeslots/TosModal.vue'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

const lesson = reactive({
  state: 'pending_instructor_confirmation',
  studentId: userStore.userData().id,
  student: {
    name: userStore.userData().name,
    photoURL: userStore.userData().photoURL,
  },
  notes: '',
  instructorId: props.timeslot.instructorId, // "HdD8YzWpyQeWqDHYuCiw9PBB5wOP"
  instructor: {
    name: props.instructor.name,
    photoURL: props.instructor.photoURL,
  },
  length: props.timeslot.length || props.timeslot.lessonType?.length,
  location: props.timeslot.location || props.timeslot.lessonType?.location,
  pricing: props.timeslot.pricing,
  description: props.timeslot.description || props.timeslot.lessonType.description,
  time: props.timeslot.time,
  formattedDate: props.timeslot.formattedDate,
  datetime: props.timeslot.datetime,
  date: props.timeslot.date, // "2024-05-26"
  inviteId: props.timeslot.inviteId,
})

const tosModalRef = ref()
const agreements = reactive({
  tos: false,
})

const goodToGo = computed(() => {
  return Object.values(agreements).every(v => v === true)
})

function cancel() {
  emit('cancel')
}

function createLesson(paymentInfo) {
  lesson.paymentInfo ||= {}
  lesson.paymentInfo.sessionId = paymentInfo.sessionId
  lesson.paymentInfo.metadata = paymentInfo.metadata
  emit('create:lesson', lesson)
}

</script>
