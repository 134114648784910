<template>
  <ion-page>
    <ion-searchbar v-model="searchTerm" />
    <ion-fab
      slot="fixed" vertical="bottom" horizontal="end"
      :edge="false"
    >
      <ion-button fill="solid" color="success" :router-link="{name: 'newOfflineStudent'}">
        <ion-icon slot="start" :icon="personAddOutline" />
        New Student
      </ion-button>
    </ion-fab>
    <ion-content color="light" fullscreen="false" data-testid="students-page">
      <template v-if="allStudents.length == 0">
        <br>
        <ion-card style="text-align:center">
          <br>
          <ion-icon :icon="warningOutline" style="font-size: 64px;" />
          <ion-card-header>
            <ion-card-title class="ion-text-capitalize">No Students Found</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            Your students will appear here once you have lessons booked.
            <br>
            Click "New Student" to create an offline student.
          </ion-card-content>
        </ion-card>
      </template>
      <ion-list v-if="allStudents.length > 0">
        <ion-item
          v-for="student in allStudents" :key="student.id" detail="true"
          data-testid="student-item" lines="full" button="true"
          :router-link="{name: 'student', params: {studentId: student.id}}"
        >
          <user-avatar slot="start" :url="student.photoURL" />

          <ion-label>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="highlightSearchTerm(student.name)" />
            <span v-if="student.id.startsWith('offline_')">
              &nbsp;
              <ion-icon :icon="cloudOfflineOutline" />
            </span>
            <template v-if="student.lessons">
              <ion-note v-if="student.lessons.completed.length">
                <br>
                {{ student.lessons.completed.length }} completed
              </ion-note>
              <ion-note v-if="student.lessons.pending.length" color="primary">
                <br>
                {{ student.lessons.pending.length }} pending
              </ion-note>
              <ion-note v-if="student.lessons.upcoming.length" color="success">
                <br>
                {{ student.lessons.upcoming.length }} upcoming
              </ion-note>
            </template>
          </ion-label>
        </ion-item>
      </ion-list>
      <p v-if="allStudents.length > 0" class="ion-text-center ion-padding">
        <ion-note>Students: {{ allStudents.length }}. Lessons Completed: {{ completedLessonsCount }}.</ion-note>
      </p>
      <div style="margin-bottom: 5em" />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent,
  IonFab, IonIcon, IonItem, IonLabel, IonList, IonNote, IonPage, IonSearchbar,
} from '@ionic/vue'
import { cloudOfflineOutline, personAddOutline, warningOutline } from 'ionicons/icons'
import { computed, ref } from 'vue'
import { escape, uniqBy } from 'lodash'

import { LessonsRepository } from '@/data/LessonsRepository'
import UserAvatar from '@/3_widgets/UserAvatar.vue'
import { useUserStore } from '@/stores/user'

const searchTerm = ref('')

const userStore = useUserStore()
const lessonsRepo = new LessonsRepository()
const instructorLessons = lessonsRepo.getInstructorLessons()

const studentsFromLessons = computed(() => {
  const result = {}
  const sortedLessons = [...instructorLessons.value].sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
  sortedLessons.map((lesson) => {
    result[lesson.studentId] ||= {
      id: lesson.studentId,
      name: lesson.student.name,
      photoURL: lesson.student.photoURL,
      lessons: { completed: [], pending: [], upcoming: [] },
    }
    result[lesson.studentId].lessons[lesson.stateGroup()] ||= []
    result[lesson.studentId].lessons[lesson.stateGroup()].push(lesson)
  })
  return result
})

const offlineStudents = userStore.instructor.fn.getOfflineStudents()

const allStudents = computed(() => {
  const onlineStudents = Object.values(studentsFromLessons.value)
  const allStudentsList = [...onlineStudents, ...offlineStudents.value]

  // select only unique students using lodash
  const uniqueStudents = uniqBy(allStudentsList, 'id')
  const sortedStudents = uniqueStudents.sort((a, b) =>
    a.name.localeCompare(b.name),
  )

  if (!searchTerm.value) {
    return sortedStudents
  }

  const searchTermLower = searchTerm.value.toLowerCase()
  return sortedStudents.filter(student =>
    student.name.toLowerCase().includes(searchTermLower),
  )
})

function highlightSearchTerm(text) {
  // html escape everything!
  text = escape(text)
  if (searchTerm.value) {
    searchTerm.value = escape(searchTerm.value)
    return text.replace(new RegExp(searchTerm.value, 'gi'), '<mark>$&</mark>')
  } else {
    return text
  }
}

const completedLessonsCount = computed(() => {
  return allStudents.value.reduce((total, student) => {
    const completedCount = student.lessons?.completed?.length || 0
    return total + completedCount
  }, 0)
})
</script>
