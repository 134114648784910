<template>
  <ion-page data-testid="package-purchase-modal">
    <ion-header>
      <ion-toolbar>
        <ion-title>Package Summary</ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="true" @click="cancel" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light">
      <ion-row class="ion-align-items-center ion-justify-content-center ion-margin-top">
        <ion-col class="ion-align-items-center ion-text-center" size="auto">
          <user-avatar :url="pckg.instructor.photoURL" :size="100" style="display:inline-block" />
          <h2>{{ pckg.instructor.name }}</h2>
          <h4>{{ pckg.lessons }} Lessons for {{ pckg.name }}</h4>
        </ion-col>
      </ion-row>

      <ion-card class="ion-no-margin ion-margin-horizontal">
        <ion-card-content>
          <ion-text color="dark">
            <strong>About Lesson Packages:</strong>
          </ion-text>
          <ul class="ion-no-margin ion-margin-top">
            <li>Work like credits without expiration</li>
            <li>Only usable for the purchased lesson type (e.g., Adult or Junior)</li>
            <li>No monetary refunds</li>
            <li>Lesson credit refunds available if cancelled at least 24 hours before the lesson</li>
          </ul>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-content>
          <ion-text v-if="!goodToGo" color="dark">
            <strong>Please accept to continue:</strong>
            <br>
          </ion-text>
          <ion-checkbox v-model="agreements.tos" label-placement="end" class="ion-padding-top">
            I agree to the <a @click.prevent="tosModalRef.$el.present()">Terms of Service</a>
          </ion-checkbox>
          <ion-modal ref="tosModalRef">
            <tos-modal @dismiss="tosModalRef.$el.dismiss()" />
          </ion-modal>
        </ion-card-content>
      </ion-card>
      <timeslot-checkout
        mode="package"
        :package="pckg"
        :good-to-go="goodToGo"
        :payment-methods="instructor.paymentMethods"
        :demo-mode="userStore.is.demoStudent"
        @payment-complete="createPackage"
      />
    </ion-content>
  </ion-page>
</template>

<script setup>
defineProps({
  pckg: { required: true, type: Object },
  instructor: { required: true, type: Object },
})

const emit = defineEmits(['cancel', 'package:purchase'])

import {
  IonBackButton, IonButtons, IonCard,
  IonCardContent, IonCheckbox, IonCol, IonContent, IonHeader, IonModal, IonPage,
  IonRow, IonText, IonTitle, IonToolbar,
} from '@ionic/vue'
import { computed, reactive, ref } from 'vue'

import TimeslotCheckout from '@/views/golfers/lessons/TimeslotCheckout.vue'
import TosModal from '@/3_widgets/timeslots/TosModal.vue'
import UserAvatar from '@/3_widgets/UserAvatar.vue'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const tosModalRef = ref()
const agreements = reactive({
  tos: false,
})

const goodToGo = computed(() => {
  return Object.values(agreements).every(v => v === true)
})

function cancel() {
  emit('cancel')
}

function createPackage(paymentInfo) {
  emit('package:purchase', paymentInfo)
}

</script>
